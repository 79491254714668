<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Reviews
        </strong>
        <hwa-button
          v-if="$can('create-review', 'hwa')"
          icon="PlusIcon"
          label="Add Review"
          @onClick="openReviewModal()"
        />
      </div>
      <b-table
        v-if="$can('view-reviews', 'hwa')"
        ref="reviewsTable"
        small
        selectable
        select-mode="single"
        :fields="fields"
        :items="reviews"
        responsive="sm"
        @row-clicked="onRowSelected"
      >
        <template #cell(status)="data">
          <b-badge :variant="data.item.approved?'light-success':'light-warning'">
            {{ data.item.approved?'Approved':'Pending' }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="reviewForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        :title="`${updateReviewMode?'Update':'Add'} Review`"
        :show="reviewModalOpened"
        size="md"
        :show-overlay="reviewLoading"
        @onClose="closeReviewModal()"
      >
        <hwa-text-area
          v-model="review.review"
          name="review"
          label="Review"
          placeholder="What do you think?"
          rules="required"
          :immediate="false"
        />
        <div>
          <span>Rate Review</span>
          <b-form-rating
            v-model="review.rating"
            variant="warning"
            inline
            no-border
            class="mt-1"
          />
        </div>
        <template slot="modal-actions">
          <div
            style="display: flex; justify-content: space-between !important;"
            class="full-width"
          >
            <hwa-button
              v-if="updateReviewMode"
              variant="flat-danger"
              icon="TrashIcon"
              class="mr-1"
              label="Delete"
              :disabled="reviewLoading"
              @onClick="deleteReview()"
            />
            <hwa-button
              variant="flat-secondary"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeReviewModal()"
            />
            <hwa-button
              variant="success"
              :label="updateReviewMode?'Save':'Save'"
              :disabled="invalid || reviewLoading"
              @onClick="updateReviewMode?updateReview():createReview()"
            />
          </div>
        </template>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BBadge, BFormRating,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  components: {
    HwaTextArea,
    HwaModal,
    HwaButton,
    BTable,
    BCard,
    BBadge,
    BFormRating,
    ValidationObserver,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: ['review', {key:'rating',label:"Rating",sortable:true}, {key:'status',label:"Status",sortable:true}],
      reviews: [],
      reviewLoading: false,
      updateReviewMode: false,
      reviewModalOpened: false,
      review: {
        id: null,
        reviewer_id: null,
        review: null,
        rating: 3,
      },
    }
  },
  mounted() {
    this.getReviews()
  },
  methods: {
    onRowSelected(item) {
      if(item.approved){
        return this.showInfo("Review has already been approved")
      }
      this.review = { ...item }
      this.updateReviewMode = true
      this.reviewModalOpened = true
    },
    openReviewModal() {
      this.updateReviewMode = false
      this.reviewModalOpened = true
      this.review = {
        id: null,
        reviewer_id: null,
        review: null,
      }
      this.$refs.reviewsTable.clearSelected()
    },
    closeReviewModal() {
      this.reviewModalOpened = false
    },
    // eslint-disable-next-line consistent-return
    deleteReview() {
      if (!this.review.id) {
        return this.showInfo('Select review first')
      }
      this.reviewLoading = true
      this.$http.delete(`/api/reviews/${this.review.id}`)
        .then(res => {
          this.reviewLoading = false
          // this.$refs.reviewsTable.clearSelected()
          this.closeReviewModal()
          this.showSuccess('Review deleted Successfully')
          this.reviews = this.reviews.filter(f => +f.id !== +this.review.id)
        })
        .catch(err => {
          this.reviewLoading = false
          this.showError('Failed to delete review')
        })
    },
    updateReview(review) {
      this.reviewLoading = true
      this.$http.put(`/api/reviews/${this.review.id}`, this.review)
        .then(res => {
          this.reviewLoading = false
          this.reviews = this.reviews.map(rev => {
            if (+rev.id === +res.data.id) {
              return res.data
            }
            return rev
          })
          this.showSuccess('Review updated successfully')
        })
        .catch(err => {
          this.reviewLoading = false
          this.showError('Failed to update review')
        })
    },
    createReview() {
      this.reviewLoading = true
      this.review.reviewer_id = this.$store.getters['auth/authUser'].id
      this.$http.post('/api/reviews', this.review)
        .then(res => {
          this.reviewLoading = false
          this.reviews = [res.data, ...this.reviews]
          this.review = {
            id: null,
            reviewer_id: null,
            review: null,
          }
          this.$refs.reviewForm.reset()
          this.showSuccess('Review created successfully')
        })
        .catch(err => {
          this.reviewLoading = false
          this.showError('Failed to create reviews')
        })
    },
    getReviews() {
      const { id } = this.$store.getters['auth/authUser']
      this.$http.get(`/api/customer-reviews/${id}`)
        .then(res => {
          this.reviews = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve reviews')
        })
    },
  },
}
</script>
